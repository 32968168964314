/* 
.FooterWrap {
    width: 100%;
    background-color: #232a34;
    color: #ffffff;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  .Footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    gap: 40px;
  }
  

  .FooterColumn {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .FooterColumn h3 {
    font-size: 1.5rem;
    color: #4a90e2;
    margin-bottom: 10px;
  }
  
  .FooterColumn p {
    font-size: 1rem;
    color: #eaeaea;
    line-height: 1.6;
  }
  
  .FooterColumn ul {
    list-style: none;
    padding: 0;
  }
  
  .FooterColumn ul li {
    margin-bottom: 10px;
  }
  
  .FooterColumn ul li a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .FooterColumn ul li a:hover {
    color: #4a90e2;
  }
  

  .FooterSocials {
    display: flex;
    gap: 15px;
  }
  
  .FooterSocials a {
    font-size: 1.5rem;
    color: #ffffff;
    transition: transform 0.3s ease;
  }
  
  .FooterSocials a:hover {
    color: #4a90e2;
    transform: scale(1.2);
  }
  

  .FooterContact {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .FooterContact svg {
    font-size: 1.5rem;
  }
  
  .FooterContact p {
    font-size: 1rem;
    color: #eaeaea;
  }
  

  .FooterNewsletter {
    width: 100%;
    max-width: 800px;
    text-align: center;
  }
  
  .FooterNewsletter h3 {
    color: #4a90e2;
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .NewsletterForm {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .NewsletterForm input {
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 250px;
  }
  
  .NewsletterForm button {
    padding: 10px 20px;
    background-color: #4a90e2;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .NewsletterForm button:hover {
    background-color: #3b7bc1;
  }
  

  .FooterBottom {
    width: 100%;
    text-align: center;
    border-top: 1px solid #4a90e2;
    padding: 20px 0;
  }
  
  .FooterBottom p {
    font-size: 1rem;
    color: #eaeaea;
  }
  

  @media (max-width: 768px) {
    .Footer {
      flex-direction: column;
      align-items: center;
    }
  
    .FooterNewsletter input {
      width: 100%;
    }
  }
   */


   .FooterWrap {
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: whitesmoke;
    color: rgba(0, 0, 255, 0.5);
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .Footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    gap: 40px;
  }
  
  .FooterColumn {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .FooterColumn img{
    width:80px;
    height: 80px;;
  }
  
  .FooterColumn h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .FooterColumn p,
  .FooterColumn a {
    font-size: 1rem;
    line-height: 1.6;
    color: rgba(0, 0, 255, 0.6);
  }
  

  .FooterColumn a{
    cursor: pointer;
  }

  .FooterColumn ul {
    list-style: none;
    padding: 0;
  }
  
  .FooterColumn ul li {
    margin-bottom: 10px;
  }
  
  .FooterColumn ul li a {
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .FooterColumn ul li a:hover {
    color: rgba(0, 0, 255, 1);
    transform: translateX(5px);
  }
  
  .FooterSocials {
    display: flex;
    gap: 15px;
  }
  
  .FooterSocials a {
    font-size: 1.5rem;
    transition: all 0.3s ease;
  }
  
  .FooterSocials a:hover {
    transform: scale(1.2);
  }
  
  .FooterContact {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .FooterContact svg {
    font-size: 1.5rem;
  }
  
  .FooterBottom {
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 255, 0.2);
    padding: 20px 0;
  }
  
  .FooterBottom p {
    font-size: 1rem;
  }
  
  .fade-slide {
    opacity: 0;
    transform: translateY(20px);
    animation: fade-slide 1s ease forwards;
  }
  
  @keyframes fade-slide {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .Footer {
      flex-direction: column;
      align-items: center;
    }
  }
  