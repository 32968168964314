/* 

.OurServicesWrap {
    font-family: 'Segoe UI', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 50px 0;
    background-color: #f9f9f9;
}

.OurServices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    padding: 0 20px;
}

.OurServicesHeading h1 {
    font-size: 3.5rem;
    color: #1e3a8a;
    margin-bottom: 30px;
}

.OurServicesBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
}

.OurService {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.OurService:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.OurService h2 {
    font-size: 1.8rem;
    color: #1e3a8a;
    margin: 20px 0 10px 0;
}

.OurService p {
    font-size: 1.1rem;
    color: #666666;
}

.OurServiceBodyIcon {
    color:#1e3a8a;

    font-size: 4.5rem;
    margin-bottom: 15px;
}

.ContactUsNowWrap {
    margin-top: 40px;
    padding: 15px;

    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.ContactUsNowWrap h1 {
    font-size: 2rem;
    margin: 0;
}

.ContactUsNowWrap:hover {

    text-decoration: underline;
    transform: scale(1.05);
}


@media screen and (max-width: 1024px) {
    .OurService {
        width: 45%;
    }

    .OurServicesHeading h1 {
        font-size: 2.7rem;
    }
}

@media screen and (max-width: 768px) {
    .OurService {
        width: 90%;
        padding: 30px;
    }

    .OurServicesHeading h1 {
        font-size: 2.5rem;
    }

    .ContactUsNowWrap h1 {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 480px) {
    .OurService {
        padding: 20px;
    }

    .OurService h2 {
        font-size: 1.5rem;
    }

    .OurService p {
        font-size: 1rem;
    }

    .OurServicesHeading h1 {
        font-size: 2.2rem;
    }
} */


.OurServicesWrap {
  /* margin-top: 10px; */
    font-family: 'Segoe UI', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 50px 0;
    background-color: #ffffff; /* White background */
    /* background-image: url('../Images/1712.jpg'); */
    background-image: url('../Images/bg5.jpg');
    /* background-image: url('../Images/office1.jpg'); */
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .OurServicesWrap::before{
      content: '';
      position: absolute;
      top:0px;
      left:0px;
      width: 100%;
      height: 100%;
      /* background-color: rgba(255, 255, 255, 0.5); */
  }
  
  .OurServices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    padding: 0 20px;
    position: relative;
  }
  
  .OurServicesHeading h1 {
    font-size: 3.5rem;
    /* color: rgba(0, 0, 255, 0.5); */
    color: white;
    margin-bottom: 30px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;

  }
  
  .OurServicesBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
  }
  
  .OurService {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeInSlide 1s ease-out; /* Animation */
  }
  
  .OurService:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.3); /* Stronger shadow */
  }
  
  .OurService h2 {
    font-size: 1.5rem;
    color: rgba(0, 0, 255, 0.5); /* Blue text color */
    margin: 20px 0 10px 0;
    animation: fadeInSlide 1s ease-out; /* Animation */ 
    /* text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9); */
    /* color:white; */
  }
  
  .OurService p {
    font-size: 1.1rem;
  
  }
  
  .OurServiceBodyIcon {
    color: rgba(0, 0, 255, 0.5); /* Blue icon color */
    font-size: 2.5rem;
   
  }
  
  .ContactUsNowWrap {
    margin-top: 40px;
    padding: 15px;
    color: rgba(0, 0, 255, 0.5);
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .ContactUsNowWrap h1 {
    font-size: 2rem;
    text-decoration: underline;
    margin: 0;
    /* color: rgba(0, 0, 255, 0.5); */
    color:white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
  }
  
  .ContactUsNowWrap:hover {
    text-decoration: underline;
    /* transform: scale(1.05); */

  }
  
  /* Animations */
  @keyframes fadeInSlide {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media screen and (max-width: 1024px) {
    .OurService {
      width: 45%;
    }
  
    .OurServicesHeading h1 {
      font-size: 2.7rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .OurService {
      width: 90%;
      padding: 30px;
    }
  
    .OurServicesHeading h1 {
      font-size: 2.5rem;
    }
  
    .ContactUsNowWrap h1 {
      font-size: 1.8rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .OurService {
      padding: 20px;
    }
  
    .OurService h2 {
      font-size: 1.5rem;
    }
  
    .OurService p {
      font-size: 1rem;
    }
  
    .OurServicesHeading h1 {
      font-size: 2.2rem;
    }
  }
  
