.TermsAndConditionsWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
}

.TermsAndConditions{
    width: 50%;
    height: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 3px 8px ;
    overflow-y: scroll;
    padding: 20px;
}

.TermsAndConditionsA{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
     
}

.TermsAndConditionsA h3{
    text-align: center;
}

.Article{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    margin: 20px;
}

.TermsAndConditionsA button{
    color: white;
    font-weight: bold;
    background-color: #1e3a8a;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1.1vw;
    width: 20%;
    padding: 10px;
    /* margin-bottom: 20px; */
    /* height: 20vh; */
}

.TermsAndConditionsA button:hover{
    /* transform: scale(1.1); */
}

.TermsAndConditionsA button:active{
    transform: scale(0.9);
}

.TermsAndConditionsAHeaderWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.TermsAndConditionsAHeaderWrapBackButton{
    color: #1e3a8a;
    font-size: 2rem;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
}

.TermsAndConditionsAHeaderWrapBackButton:hover{
    /* transform: scale(1.2); */
    color: blue;
}

.TermsAndConditionsAHeaderWrapBackButton:active{
    transform: scale(0.9);
}

.TermsAndConditionsAHeaderWrap{

}

.TermsAndConditionsAHeaderWrap h3{
    color:#1e3a8a;
    font-size: 2rem;
}

.TermsAndConditionsA h3{
    
}

.Article h4{
    color: #1e3a8a;
}

@media screen and (max-width:884px) {
    .TermsAndConditions{
        width: 90%;
        height: 80%;
        font-size: 1.5rem;
    }
    .TermsAndConditionsA button{
        font-size: 1.5rem;
    }
    
    
}

@media screen and (max-width:428px) {
    .TermsAndConditions{
        width: 90%;
        font-size: 1rem;
    }
    .TermsAndConditionsA button{
        font-size: 1rem;
        margin: 20px;
    }
}