 .ContactUsWrap{
    /* background-color: rgba(0,0,255,0.1); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: auto; */
    padding-top: 100px;
    padding-bottom: 50px;
    /* margin-bottom: 10vh; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: url('../Images/9875.jpg');
    position: relative;
    background-size: cover;
}

.ContactUsWrap::before{
    content: '';
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
}

.AboutUsWrap{
    /* background-color: rgba(0,0,255,0.1); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: auto; */
    padding-top: 100px;
    padding-bottom: 50px;
    /* margin-bottom: 10vh; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: url('../Images/aboutbg.jpg');
    position: relative;
    background-size: cover;
}

.AboutUsWrap::before{
    content: '';
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
}

.ContactUs{
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    position: relative;
}


.ContactUsHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: rgba(0, 0, 255, 0.7); */
    /* color: #1e3a8a; */
    /* color:rgba(0, 0, 255, 0.5); */
    text-align: center;
}

.ContactUsHeader h1{
    font-size: 3rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
    color: white;
}
.ContactUsBody{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-around;
    align-items: center;
 
}

.ContactUsBodyLeft{
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 20px; */
}

.ContactUsBodyLeft form{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 20px;
}

.ContactUsBodyLeft form label{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 10%; */
    margin: 10px;
    
}

.InputLabelChild{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ContactUsBodyLeft form label input{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 255, 0.5);
    padding-left: 2%;
    /* margin: 10px; */
}


.ContactUsBodyLeft form label input:hover{
    border: 2px solid rgba(0, 0, 255, 0.5);
}

.ContactUsBodyRight{
    width: 50%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ContactUsBodyRight img{
    width: 70%;
    /* height: 100%; */
    border-radius: 50%;
}

.CountryCityStateWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8%;

    gap: 5%;
}

.CountryCityStateWrap input{
    width: 22%;
    height: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, 0.5);
    padding-left: 2%;
}

.CountryCityStateWrap input:hover{
    border: 2px solid rgba(0, 0, 255, 0.5);
}

.TextAreaWrap{
    width: 100%;
    /* height: 100px; */

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    
}

.InputLabelChild textarea{
    width: 100%;
    height: 100px;
    resize: none;
    outline: none;
    padding: 1%;
    border:  1px solid rgba(0, 0, 255, 0.5);
    border-radius: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.TextAreaWrap textarea:hover{
    border: 2px solid rgba(0, 0, 255, 0.5);
}

.SubmitButtonWrap{
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    gap:50%;
    /* height: 7%; */
    padding-top: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: red; */


}

.SubmitButtonWrap button{
    color: white;
    font-weight: bold;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    background-color: rgba(0, 0, 255, 0.5);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1rem;
    width: 100%;
    padding: 10px;
    /* width: 20%;
    height: 70%; */
}

.SubmitButtonWrap button:hover{
    /* transform: scale(1.1); */
}

.SubmitButtonWrap button:active{
    transform: scale(0.9);
}

.FormSubmitUiWrap{
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    /* background-color: rgba(0, 0, 255, 0.1); */
    top:0;
    left: 0;
    z-index: 11;
}

.FormSubmitUi{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: auto;
    background-color: whitesmoke;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px ;
    padding: 5%;
    text-align: center;
    font-size: 1.2rem;
}

.FormSubmitUi img{
    width: 20%;
}

.FormSubmitUi button{
    color: white;
    font-weight: bold;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    background-color:rgba(0, 0, 255, 0.5);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1rem;
    padding: 10px;
    margin: 20px;
    width: 100px;
    /* width: 40%;
    height: 25%; */
}

.FormSubmitUi button:hover{
    /* transform: scale(1.1); */
}

.FormSubmitUi button:active{
    transform: scale(0.9);
}

.BackButtonWrap{
    display: flex;
    width: 100%;
    gap:50%;
    padding-top: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.BackButtonWrap button{
    color: rgba(0, 0, 255, 0.5);
    font-weight: bold;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    background-color:white;
    border: 1px solid rgba(0, 0, 255, 0.5);
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 0.9rem;
    padding: 10px;
}

.BackButtonWrap button:hover{
    /* transform: scale(1.1); */
}

.BackButtonWrap button:active{
    transform: scale(0.9);
}


@media screen and (max-width:1024px) {

}

@media screen and (max-width:768px) {
   .ContactUsBody{
    flex-direction: column;
   }
   .ContactUsBodyLeft{
    width: 100%;
    padding-left: 0;
   }
   .ContactUsBodyRight{
    width: 100%;
   }
   .ContactUsHeader h1{
    font-size: 2rem;
}
.ContactUsWrap{
    padding-top: 100px;
}
}


@media screen and (max-width:428px) {
    .FormSubmitUi{
        width: 90%;
        font-size: 1.3rem;
    }
    .FormSubmitUi button{
        font-size: 1.2rem;
    }
}

@media screen and (max-width:320px) {
   
}



/*  
@media screen and (max-width: 884px){
    .ContactUsHeader{
        margin-top: 20px;
        
    }
    .ContactUsBody{
        flex-direction: column;
        height: max-content;
    }    
    .ContactUsBodyLeft{
        width: 100%;
        
    }

    .ContactUsBodyRight{
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
    }
    .ContactUsBodyLeft form{
        height: 120vh;
        font-size: 2vw;
    }

    .ContactUsBodyLeft form input{
        font-size: 2vw;
    }
    .SubmitButtonWrap button{
        font-size: 2.2vw;
    }

}

@media screen and (max-width: 768px){
    .ContactUsBody{
        flex-direction: column;
        height: max-content;
    }    
    .ContactUsBodyLeft{
        width: 100%;
        
    }

    .ContactUsBodyRight{
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
    }
    .ContactUsBodyLeft form{
        height: 120vh;
        font-size: 2.5vw;
    }

    .ContactUsBodyLeft form input{
        font-size: 2.3vw;
    }
    .SubmitButtonWrap button{
        font-size: 2.5vw;
    }

}

@media screen and (max-width: 428px){
    .ContactUsBody{
        flex-direction: column;
        height: max-content;
    }    
    .ContactUsBodyLeft{
        width: 100%;
        
    }

    .ContactUsBodyRight{
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
    }
    .ContactUsBodyLeft form{
        height: 120vh;
        font-size: 3.5vw;
    }

    .ContactUsBodyLeft form input{
        font-size: 3.5vw;
    }
    .SubmitButtonWrap button{
        font-size: 3.5vw;
    }

}
 
 
@media screen and (max-width: 375px){
    .ContactUsBody{
        flex-direction: column;
        height: max-content;
    }    
    .ContactUsBodyLeft{
        width: 100%;

    }

    .ContactUsBodyRight{
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
    }
    .ContactUsBodyLeft form{
        height: 160vh;
        font-size: 15px;
    }

    .ContactUsBodyLeft form input{
        font-size: 14px;
    }
    .SubmitButtonWrap button{
        font-size: 14px;
        width: 30%;
    }

} */
