.FrontEndTrainingOfferWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 255, 0.1);

    width: 100%;
    height: auto;
    padding-top: 150px;
    padding-bottom: 50px;
    /* margin-bottom: 10vh; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.FrontEndTrainingOffer{
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}


.FrontEndTrainingOfferHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1e3a8a;
    text-align: center;
}

.FrontEndTrainingOfferHeader h1{
    font-size: 3rem;
}
.FrontEndTrainingOfferBody{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-around;
    align-items: center;
 
}

.FrontEndTrainingOfferBodyLeft{
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 20px; */
}

.FrontEndTrainingOfferBodyLeft form{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 50px;
}

.FrontEndTrainingOfferBodyLeft form label{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 10%; */
    margin: 10px;
    
}

.FrontEndTrainingOfferInputLabelChild{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.FrontEndTrainingOfferBodyLeft form label input{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 255, 0.5);
    padding-left: 2%;
    /* margin: 10px; */
}

.FrontEndTrainingOfferBodyLeft form label input:hover{
    border: 2px solid rgba(0, 0, 255, 0.5);
}

.FrontEndTrainingOfferBodyRight{
    width: 50%;
    display: flex;
    font-size: 1.5rem;
    padding: 0% 5%;
    font-style: italic;
    flex-direction: column;
    gap:10%;
    justify-content: flex-start;
}

.FrontEndTrainingOfferBodyRight img{
    width: 70%;
    /* height: 100%; */
    border-radius: 50%;
}

.CountryCityStateWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8%;
    gap: 5%;
}

.CountryCityStateWrap input{
    width: 22%;
    height: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, 0.5);
    padding-left: 2%;
}

.CountryCityStateWrap input:hover{
    border: 2px solid rgba(0, 0, 255, 0.5);
}

.FrontEndTrainingOfferTextAreaWrap{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-left:10% ;
}

.FrontEndTrainingOfferTextAreaWrap textarea{
    width: 80%;
    height: 100px;
    resize: none;
    outline: none;
    padding: 1%;
    border:  1px solid rgba(0, 0, 255, 0.5);
    border-radius: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.FrontEndTrainingOfferTextAreaWrap textarea:hover{
    border: 2px solid rgba(0, 0, 255, 0.5);
}

.FrontEndTrainingOfferSubmitButtonWrap{
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    gap:50%;
    /* height: 7%; */
    padding-top: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: red; */

}

.FrontEndTrainingOfferSubmitButtonWrap button{
    color: white;
    font-weight: bold;
    background-color: #1e3a8a;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1rem;
    padding: 10px;
    width: 100%;
    /* width: 20%;
    height: 70%; */
}

.FrontEndTrainingOfferSubmitButtonWrap button:hover{
    /* transform: scale(1.1); */
}

.FrontEndTrainingOfferSubmitButtonWrap button:active{
    transform: scale(0.9);
}

.FrontEndTrainingOfferFormSubmitUiWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top:0;
    left: 0;
    z-index: 1;
}

.FrontEndTrainingOfferFormSubmitUi{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: auto;
    background-color: whitesmoke;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px ;
    padding: 5%;
    text-align: center;
    font-size: 2rem;
}

.FrontEndTrainingOfferFormSubmitUi button{
    color: white;
    font-weight: bold;
    background-color: #1e3a8a;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1.5rem;
    padding: 10px;
    margin: 20px;
    width: 100px;
    /* width: 40%;
    height: 25%; */
}

.FrontEndTrainingOfferFormSubmitUi button:hover{
    transform: scale(1.1);
}
.FrontEndTrainingOfferFormSubmitUi button:active{

    transform: scale(0.9);
}

.FrontEndTrainingOfferBackButtonWrap{
    display: flex;
    width: 100%;
    gap:50%;
    padding-top: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.FrontEndTrainingOfferBackButtonWrap button{
    color: white;
    font-weight: bold;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    border: none;
        border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1rem;
    padding: 15px;
}

.FrontEndTrainingOfferBackButtonWrap button:hover{
    transform: scale(1.1);
}

.FrontEndTrainingOfferBackButtonWrap button:active{
    transform: scale(0.9);
}


.FrontEndTrainingOfferTermsAndConditionsAgreeWrap{
    display: flex;
    justify-content: center;
    gap:2%;
    align-items: center;
    width: 100%;
    /* height: 8%; */
    flex-direction: column;
    margin: 10px;
    /* background-color: red; */
}

.FrontEndTrainingOfferTermsAndConditionsAgreeWrap input{
    cursor: pointer;
}

.FrontEndTrainingOfferTermsAndConditionsAgreeWrap p span{
    font-weight: bold;
    color: blue;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
}

.FrontEndTrainingOfferTermsAndConditionsAgreeWrap p span:active{
    transform: scale(1.2);
}

.FrontEndTrainingOfferBodyRight{
    
    /* background-color: red; */
}

.FrontEndTrainingImgWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    /* height: 40vh; */
}

.FrontEndTrainingImgWrap img{
    width: 100%;
    /* height: 100%; */
    animation: flip 5s infinite;
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.FrontEndTrainingformNote{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding-left: 10%; */
    padding-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
    font-style: italic;

}

.FrontEndTrainingOfferTermsAndConditionsAgreeWrapUp{
    display: flex;
    width: 45%;
    justify-content: space-around;
    align-items: center;
}

.FrontEndTrainingOfferBackButtonWrap{
    display: flex;
    width: 100%;
    gap:50%;
    padding-top: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.FrontEndTrainingOfferBackButtonWrap button{
    color: #1e3a8a;
    font-weight: bold;
    background-color: white;
    /* background: linear-gradient(to bottom, rgb(179, 179, 231), blue); */
    border: 1px solid #1e3a8a;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 0.9rem;
    padding: 10px;
}

.FrontEndTrainingOfferBackButtonWrap button:hover{
    /* transform: scale(1.1); */
}

.FrontEndTrainingOfferBackButtonWrap button:active{
    transform: scale(0.9);
}

@media screen and (max-width:1024px) {
    .FrontEndTrainingOfferTermsAndConditionsAgreeWrapUp{
        width: 70%;
        /* background-color: red; */
    }
    .FrontEndTrainingImgWrap{
        width: 70%;
    }
}

@media screen and (max-width:768px) {
   .FrontEndTrainingOfferBody{
    flex-direction: column;
   }
   .FrontEndTrainingOfferBodyLeft{
    width: 100%;
    padding-left: 0;
   }
   .FrontEndTrainingOfferBodyRight{
    width: 100%;
   }
   .FrontEndTrainingOfferHeader h1{
    font-size: 2rem;
}
.FrontEndTrainingOfferWrap{
    padding-top: 100px;
}
.FrontEndTrainingOfferBodyRight{
    text-align: center;

}
.FrontEndTrainingOfferTermsAndConditionsAgreeWrapUp{
    width: 40%;
}
.FrontEndTrainingImgWrap{
    width: 70%;
    margin: 30px;
}
}


@media screen and (max-width:428px) {
    .FrontEndTrainingOfferFormSubmitUi{
        width: 90%;
        font-size: 1.3rem;
    }
    .FrontEndTrainingOfferFormSubmitUi button{
        font-size: 1.2rem;
    }
    .FrontEndTrainingOfferTermsAndConditionsAgreeWrapUp{
        width: 100%;
        justify-content: center;
        gap:5px;
        text-align: center;
        /* background-color: red; */
       }
       .FrontEndTrainingOfferBodyLeft form{
        padding: 5px;
       }
}

@media screen and (max-width:320px) {
   
}