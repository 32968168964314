*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    
}

.swal2-popup {
    /* background-color: greenyellow; */
    /* font-size: 1.5vw; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 30%; */
    /* height: 70%; */
    z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999;
  }

.swal2-popup .swal2-title {
    font-family: sans-serif;
    /* font-size: 26px; */
    /* font-size: 4vw; */
    color:rgba(0, 0, 255, 0.5);   
  }
  
  .swal2-popup .swal2-text {
    font-family: sans-serif;
    /* font-size: 4vw; */
  }
  
/* 
  @media screen and (max-width:884px) {
    .swal2-popup{
      font-size: 3vw;
      width: 50%;
    } 
  }

  @media screen and (max-width:428px) {
    .swal2-popup{
      font-size: 4vw;
      width: 70%;
    } 
  } */


 
/* whats app icon styling */
.WhatsAppIcon{
  position: fixed;
  z-index: 99;
  /* bottom: 50px; */
  /* right: 50px; */
 bottom: 15%;
 right: 2%; 
  width: 70px;
  box-shadow: 0px 4px 5px;
  border-radius: 50%;
  cursor: pointer;
}

/* @media screen and (max-width:768px) {
  .WhatsAppIcon{
      width: 50px;
      bottom: 120px;
      right: 30px;
  }

  
}

@media screen and (max-width:428px) {
  .WhatsAppIcon{
      bottom: 5%;
      right: 5%;
  }
} */