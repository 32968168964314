/* 
  
  .HeaderWrap {
    width: 100%;

    background-color: #232a34;
    position: fixed;
    top: 0;
    z-index: 1000;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .Header {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .HeaderLeft img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .NavToggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .NavToggle .Bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: all 0.3s ease;
  }
  
  .NavToggle.Open .Bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .NavToggle.Open .Bar:nth-child(2) {
    opacity: 0;
  }
  
  .NavToggle.Open .Bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  .HeaderRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    transition: all 0.3s ease;
  }
  
  .HeaderRight a {
    text-decoration: none;

    padding: 10px 15px;
    border-radius: 30px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 1rem;
  }
  
  .HeaderRight a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }
  
  .HeaderRightActive {
    background-color: #4a90e2;
  }
  
  .HeaderRight.Show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #1f1f2e;
    padding: 20px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .NavToggle {
      display: flex;
    }
  
    .HeaderRight {
      position: absolute;
      top: -100vh;
      right: 0;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      background-color: #1f1f2e;
      border-radius: 10px;
    }
  
    .HeaderRight.Show {
      top: 60px;
    }
  }
  
  @media (max-width: 480px) {
    .HeaderRight a {
      font-size: 1rem;
      padding: 8px 12px;
    }
  
    .HeaderLeft img {
      width: 40px;
      height: 40px;
    }
  }
   */



   .HeaderWrap {
    width: 100%;
    background-color: white; /* Set background color to white */
    position: fixed;
    top: 0;
    z-index: 1000;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow */
    transition: all 0.3s ease;
  }
  
  .Header {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.HeaderLeft{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
}

.HeaderLeft h1{
  font-size: 2rem;
  color: rgba(0, 0, 255, 0.5);
  font-style: italic;
  font-family:Brush Script MT, Brush Script Std, cursive ;
}
  
  .HeaderLeft img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .NavToggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .NavToggle .Bar {
    width: 25px;
    height: 3px;
    background-color: rgba(0, 0, 255, 0.7); /* Blue color for bars */
    margin: 4px 0;
    transition: all 0.3s ease;
  }
  
  .NavToggle.Open .Bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .NavToggle.Open .Bar:nth-child(2) {
    opacity: 0;
  }
  
  .NavToggle.Open .Bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  .HeaderRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    transition: all 0.5s ease; /* Smooth transition */
  }
  
  .HeaderRight a {
    text-decoration: none;
    color: rgba(0, 0, 255, 0.7); /* Blue text color */
    padding: 5px 15px;
    border-radius: 30px;
    transition: all 0.4s ease;
    font-size: 0.9rem;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeSlideIn 0.8s forwards;
    /* cursor: pointer; */
  }
  
  .HeaderRight a:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .HeaderRight a:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .HeaderRight a:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .HeaderRight a:hover {
    background-color: rgba(0, 0, 255, 0.1);
    transform: scale(1.1);
  }
  
  .HeaderRightActive {
    background-color: rgba(0, 0, 255, 0.2);
  }
  
  .HeaderRight.Show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow */
    padding: 20px;
    border-radius: 10px;
  }
  
  @keyframes fadeSlideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .NavToggle {
      display: flex;
    }
  
    .HeaderRight {
      position: absolute;
      top: -100vh;
      right: 0;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      background-color: white;
      border-radius: 10px;
    }
  
    .HeaderRight.Show {
      top: 60px;
    }
  }
  
  @media (max-width: 480px) {
    .HeaderRight a {
      font-size: 1rem;
      padding: 8px 12px;
    }
  
    .HeaderLeft img {
      width: 40px;
      height: 40px;
    }
  }
  