/* 

 .WhyTrainWithUsWrap {

    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 30px;
    min-height: 100vh; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.WhyTrainWithUs {
    width: 90%; 
    max-width: 800px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px; 
}

.WhyTrainWithUsHeading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; 
    font-size: 3vw;
    color: rgb(111, 111, 247);
    margin-bottom: 20px;
}

.WhyTrainWithUsBody {
    width: 100%;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-around;
    align-items: center;
}

.WhyTrainWithUsBodyItem {
    width: 90%; 
    max-width: 400px; 
    margin-bottom: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-size: 1.2rem; 
    font-weight: 500;
}

.WhyTrainWithUsBodyItem img {
    width: 20px; 
    margin-right: 10px; 
}

.ContactUsNowWrap {
    margin-top: 20px; 
    text-align: center;
}

.ContactUsNowWrap h1 {
    cursor: pointer;
    font-size: 1.5rem; 
    color: blue; 
}
 */


 .WhyTrainWithUsWrap {
    background-color: #f9f9f9; /* Light background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px 5px;
    min-height: 100vh; /* Ensure it covers the viewport height */
    font-family: 'Arial', sans-serif; /* Use a modern font */
    background-image: url('../Images/1712.jpg');
    background-image: url('../Images/bg6.jpg');
    background-position: center;
    position: relative;
    background-size: cover;
}

.WhyTrainWithUsWrap::before{
    content: '';
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.5); */
}

.WhyTrainWithUs {
    width: 90%; /* Adjust width */
    max-width: 800px; /* Add max-width */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Add padding */
    position: relative;
}

.WhyTrainWithUsHeading {
    /* width: 100%; */
    text-align: center;
    font-size: 1rem; /* Adjust heading size */
    /* color:rgba(0,0,255,0.5) ; */
    margin-bottom: 30px; /* Increase bottom margin */
    color: white;

    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
}

.WhyTrainWithUsBody {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
}

.WhyTrainWithUsBodyItem {
    width: 100%; /* Take full width */
    max-width: 600px;
    margin-bottom: 20px; /* Add margin */
    display: flex;
    align-items: center; /* Align items vertically */
    padding: 15px; /* Add padding */
    background: white; /* Card background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s, box-shadow 0.3s; /* Add transition for hover effect */
}

.WhyTrainWithUsBodyItem:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.WhyTrainWithUsBodyItem img {
    width: 40px; /* Adjust image size */
    margin-right: 15px; /* Add margin */
}

.WhyTrainWithUsBodyItem p {
    font-size: 1.1rem; /* Adjust font size */
    color: #333; /* Darker text for better readability */
}

.ContactUsNowWrap {
    margin-top: 30px; /* Add margin */
    text-align: center;
}

.ContactUsNowWrap h1 {
    cursor: pointer;
    font-size: 1.5rem; /* Adjust font size */
    color: #1e3a8a; /* Change color */
    transition: color 0.3s; /* Add transition */
}

.ContactUsNowWrap h1:hover {
    color: darkblue; /* Darker blue on hover */
}

