.TrainingOnlyPageWrap{
    display: flex;
    flex-direction: column;
    padding-top: 15vh;
    justify-content: center;
    align-items: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.TrainingOnlyPageWrap h1{
    font-size: 4vw;
    color: rgba(0, 0, 255, 0.5);
}

.TrainingOnlyPageWrapH1{
    color: blue;
}

.TrainingOnlyPageWrap p{
    font-style: italic;
}