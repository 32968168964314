.ourpartnerswrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    gap: 10px;
}

.ourpartnerswrap h2{
    color: rgba(0,0, 255, 0.5);
}

.ourpartners{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.ourpartners img{
    width: 100px;
    height: 100px;
}